/*global google*/
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadScript, GoogleMap } from "@react-google-maps/api";
import Market from "./Market";
import { DistanceMatrixService } from "@react-google-maps/api";
import Panel  from "./Panel";
import MobileResults from "./MobileResults";
import Markets from "./markets.ts";
import {GetNearByFromCenter, GetNearByAddress, ParseResponse} from "./utils";
import utils from "./utils";

const libraries = ["places"];

export default function Map(props) {
  console.log("Markets", Markets)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [storeid, setCard] = useState("");
  const [center, setCenter] = useState(props.defaultCenter);
  const [week, SetDow] = useState(utils.WeekArray);
  const [nearby, setNearby] = useState(GetNearByFromCenter(center, week, Markets));

  const mapContainerStyle = matches ? {
    height: "70vh",
    width: "100%",
    marginTop: "65px"
  } : {
    height: "70vh",
    width: "100%",
    marginTop: "115px"
  };
  
  let distanceMatrixService;
  const onMapLoad = (map) => {
    distanceMatrixService = new google.maps.DistanceMatrixService();
  };  

  const handleClick = (center) => {
    setCenter(previousState => {
      console.log("previousState", previousState);
      return center;
    });
    setNearby(GetNearByFromCenter(center, week, Markets));
  }

  const handleWeekChecked = (week) => {
    SetDow(week);
    setNearby(GetNearByFromCenter(center, week, Markets));
  }
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
      libraries={libraries}
    >
      <Panel handleLocationChange={handleClick} openedid={storeid} matches={matches} nearby={nearby} setDow={handleWeekChecked} week={week}/>
      <GoogleMap
        id="marker1"
        mapContainerStyle={mapContainerStyle}
        zoom={11}
        center={center}
        onLoad={map => onMapLoad(map)}
        onClick={() => setCard("")}
      >
        <Market setCard={setCard} openedid={storeid} matches={matches} markets={nearby} />
      </GoogleMap>
      {matches ? ("") : (<MobileResults openedid={storeid} matches={matches} nearby={nearby} setCard={setCard}></MobileResults>)}
    </LoadScript>
  );
}
